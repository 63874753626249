import React, { useEffect } from 'react';
import styled from 'styled-components';
import { tablet } from 'styles/breakpoints';
import { Seo, Text } from 'components';
import Header from 'components/reviews/Header';
import Footer from 'components/reviews/Footer';
import LCFooter from 'components/LCFooter';

const Terms = () => {
  useEffect(() => {
    document.body.style.overflow = 'auto';
  }, []);

  return (
    <>
      <Seo />
      <Header isMobileApp={false} />
      <Container>
        <Wrapper>
          <StyledTitle>General Terms and Conditions</StyledTitle>
          <StyledHeading>1. Introduction</StyledHeading>
          <StyledText>
            1.1. These General Terms and Conditions (the “Agreement”) governs
            the entire relationship between you the Client and the Company.
          </StyledText>
          <StyledText>
            1.2. Before the Distance contract is concluded, the Client will be
            provided with the text of this Agreement electronically or in other
            durable format. If this is not reasonably possible, the Company will
            indicate, before the Distance contract is concluded, in what way
            this Agreement is available for Client`s review at the Company`s
            premises and that they will be sent free of charge to the Client, as
            soon as possible, at the Client`s request.
          </StyledText>
          <StyledText>
            1.3. THE CLIENT IS OBLIGED TO CAREFULLY READ THIS AGREEMENT BEFORE
            ACCEPTING IT AND USING THE SERVICES OF THE COMPANY. THE CLIENT
            AGREES THAT HIS/HER USE OF THE SERVICES ACKNOWLEDGES THAT THE CLIENT
            HAS READ THIS AGREEMENT, UNDERSTOOD IT, AND AGREED TO BE BOUND BY
            IT.
          </StyledText>
          <StyledText>
            1.4. This Agreement contains a mandatory arbitration provision that,
            as further set forth in Section 17 below, requires the use of
            arbitration on an individual basis to resolve disputes, rather than
            jury trials or any other court proceedings, or class actions of any
            kind.
          </StyledText>
          <StyledHeading>2. Definitions</StyledHeading>
          <StyledText>
            2.1. Unless this Agreement provide otherwise, wherever used in this
            Agreement, including the introductory part, the following terms when
            capitalized shall have the following meanings:
          </StyledText>
          <StyledList>
            <StyledListElement>
              Agreement - Agreement for providing Services and/or Goods
              concluded online by the Company and the Client.
            </StyledListElement>
            <StyledListElement>
              Client - user of the Company’s Services and/or the buyer of Goods
              as explained in this Agreement.
            </StyledListElement>
            <StyledListElement>
              Company - shall mean UAB &quot;Medical Score&quot;, company code
              305445861, with our registered office at J. Žemgulio g. 46,
              LT-35239 Panevėžys, the Republic of Lithuania, email
              hello@nova.health, which is responsible for providing the
              subscription services and handling Client’s inquires, including
              refunds and chargebacks for the associated Company for the Goods
              and Services.
            </StyledListElement>
            <StyledListElement>
              Offer - the offer to enter into this Agreement of Services and
              Goods provided by Company to the Client through the Website.
            </StyledListElement>
            <StyledListElement>
              Privacy Policy - the privacy policy of the Company published on
              the Website.
            </StyledListElement>
            <StyledListElement>
              Services - as the accessibility to the Website, including
              information, text, images offered or provided there.
            </StyledListElement>
            <StyledListElement>
              Digital content - individual digital content sold from time to
              time online by the Company.
            </StyledListElement>
            <StyledListElement>
              Goods - supplements and/or other products in physical form sold
              online by the Company
            </StyledListElement>
            <StyledListElement>
              Distance contract - a contract concluded between the Company and
              the Client within the framework of a system organized for the
              distance sale of Goods.
            </StyledListElement>
            <StyledListElement>
              Website - the website of the Company available at
              https://nova.health
            </StyledListElement>
          </StyledList>
          <StyledHeading>3. Submission of the Offer</StyledHeading>
          <StyledText>
            3.1. The Company will provide the Client with a possibility of
            receiving an Offer.
          </StyledText>
          <StyledText>
            3.2. The Client will be asked to provide certain information through
            before receiving the Offer by choosing provided options or typing
            requested details. The Client is obliged to provide current, correct
            and comprehensive information that is requested to be provided.
          </StyledText>
          <StyledText>
            3.3. Upon submission of the information established in Section 3.2
            of this Agreement, the Client will be provided with the Offer. The
            Offer will include information on the following:
          </StyledText>
          <StyledText>
            3.3.1. payment amount for the relevant Services and/or Goods;
          </StyledText>
          <StyledText>
            3.3.2. payment options: via credit card or other allowable payment
            form;
          </StyledText>
          <StyledText>
            3.3.3. other information Company finds important to include in the
            Offer.
          </StyledText>
          <StyledText>3.4. Accepting the Offer</StyledText>
          <StyledText>
            3.4.1. The Client accepts the Offer once he/she ticks the box “I
            agree with the Terms & Conditions”. Once the Client agrees with the
            Terms & Conditions, the Client will be required to press the button
            “Submit”.
          </StyledText>
          <StyledHeading>4. Distance contract</StyledHeading>
          <StyledText>
            4.1. The Distance contract will be concluded at the moment when the
            Client accepts the Offer and as indicated in paragraph 3.4.1.
          </StyledText>
          <StyledText>
            4.2. As the Client will accept the Offer electronically, the Company
            will confirm receipt of acceptance of the Offer electronically.
          </StyledText>
          <StyledText>
            4.3. The Company makes reasonable efforts to ensure that Services
            operate as intended, however such Services are dependent upon
            internet and other services and providers outside of the control of
            the Company. By using Company`s Services, the Client acknowledges
            that the Company cannot guarantee that Services will be
            uninterrupted, error free or that the information it contains will
            be entirely free from viruses, hackers, intrusions, unscheduled
            downtime or other failures. The Client expressly assumes the risk of
            using or downloading such Services.
          </StyledText>
          <StyledText>
            4.4. From time to time and without prior notice to the Client, we
            may change, expand and improve the Services. We may also, at any
            time, cease to continue operating part or all of the Services or
            selectively disable certain aspects of the Services. Any
            modification or elimination of the Services will be done in our sole
            and absolute discretion and without an ongoing obligation or
            liability to the Client, and the Client use of the Services do not
            entitle the Client to the continued provision or availability of the
            Services.
          </StyledText>
          <StyledText>4.5. The Client furthermore agrees that:</StyledText>
          <StyledText>
            4.6.1 he/she shall not access Services (including for purchasing
            Goods) if he/she is under the age of 18;
          </StyledText>
          <StyledText>
            4.6.2 The Client will deny access of Services to children under the
            age of 18. The Client accepts full responsibility for any
            unauthorized use of the Services by minors.
          </StyledText>
          <StyledHeading>5. Payments</StyledHeading>
          <StyledText>
            5.1. During the period of validity indicated in the Offer, the price
            for the Services and/or Goods being offered will not increase,
            except for price changes in VAT-tariffs.
          </StyledText>
          <StyledText>5.2. The Client agrees to:</StyledText>
          <StyledText>
            5.2.1. pay all additional costs, fees, charges, applicable taxes and
            other charges that can be incurred by the Client;
          </StyledText>
          <StyledText>
            5.2.2. purchase Services and/or Goods by using valid credit card or
            other allowed form of payment;
          </StyledText>
          <StyledText>
            5.2.3. provide Company current, correct and comprehensive
            information as detailed in the purchase order form. If Company
            discovers or believes that any information provided by Client is not
            current, inaccurate or incomplete, Company reserves the right to
            suspend the Service and/or delivery of Goods at its sole discretion
            and Client forfeits any right to refund paid amount.
          </StyledText>
          <StyledText>
            5.3. After the Client is transferred to the third party payment
            services, the risk of loss or damages will pass on the Client and/or
            third party service. The Client’s online credit or debit card
            payments to the Company will be handled and processed by third party
            payment service provider and none of the sensitive data in relation
            to your payment will be stored on or used by the Company. The
            Company shall not be liable for any payment issues or other disputes
            that arise due to the third party payment services. The Company may
            change the third party payment service provider from time to time.
          </StyledText>
          <StyledText>
            5.4. All prices and costs are in US Dollars unless otherwise
            indicated.
          </StyledText>
          <StyledText>
            5.5. All Goods remain Company’s property until full payment is made.
            The price applicable is that set at the date on which you place your
            order. Shipping costs and payment fees are recognized before
            confirming the purchase. If you are under 18 years old you must have
            parents’ permission to buy from the Company.
          </StyledText>
          <StyledText>
            5.6. All transfers conducted through the Company are handled and
            transacted through third party dedicated gateways to guarantee your
            protection. Card information is not stored and all card information
            is handled over SSL encryption. Please read the terms & conditions
            for the payment gateway chosen for the transaction as they are
            responsible for the transactions made.
          </StyledText>
          <StyledText>
            5.7. Your payments are processed by UAB &quot;Medical Score&quot;,
            company code 305445861, with our registered office at J. Žemgulio g.
            46, LT-35239 Panevėžys, the Republic of Lithuania. For a refund or a
            complaint, please contact hello@nova.health. Please note that local
            charges (sales tax, customs duty) may occur, depending on your
            region and local customs duties. These charges are at the customers
            own expense.
          </StyledText>
          <StyledHeading>6. Subscription.</StyledHeading>

          <StyledText>
            6.1 In order to ensure that Client does not experience an
            interruption or loss of Services, the Services are offered on
            AUTOMATIC RENEWAL.
          </StyledText>
          <StyledText>
            6.2 EXCEPT FOR REASONS DESCRIBED BELOW IN THIS SECTION, AUTOMATIC
            RENEWAL AUTOMATICALLY RENEWS THE APPLICABLE SERVICE UPON EXPIRATION
            OF THE CURRENT TERM FOR A RENEWAL PERIOD EQUAL IN TIME TO THE MOST
            RECENT SERVICE PERIOD. For example, if Clients last service period
            is for one year, the renewal period will typically be for one year.
          </StyledText>
          <StyledText>
            6.3 Unless Client cancels the subscription, Company will
            automatically renew the applicable service when it comes up for
            renewal and will take payment from the payment method associated
            with the Service in Client’s account.
          </StyledText>
          <StyledText>
            6.4 The Company may change the subscription plans and the price of
            the Services from time to time. Renewals will be charged at
            Company’s then current rates, which Client acknowledges and agrees
            may be higher or lower than the rates for the original service
            period.
          </StyledText>
          <StyledText>
            6.5 IF CLIENT DOES NOT WISH FOR SERVICE TO AUTOMATICALLY RENEW, he
            may elect to cancel the subscription at least 48 hours before the
            end of current period, in which case, the Services will be
            terminated upon expiration of the then current term, unless he
            manually renews the Services prior to that date.
          </StyledText>
          <StyledText>
            6.5.1 If Client does not cancel at least 48 hours before the end of
            the current period, the subscription will automatically renew, and
            any cancellation will be for the end of the renewed period. For
            digital content subscriptions, this means that Client will have
            access to digital content for the duration of the renewal term. For
            physical Goods (such as Supplements) subscriptions, this means that
            the Client will receive the recurring shipment of Supplements.
          </StyledText>
          <StyledText>
            6.5.2 IN CASE THE CLIENT PURCHASED A SUBSCRIPTION AND WISHES TO
            CANCEL THE SUBSCRIPTION BEFORE SECOND SUBSCRIPTION CHARGE IS MADE,
            THEN THE COMPANY WILL REQUIRE THE CLIENT TO RETURN ALL DISCOUNTS
            APPLIED. OUR SUBSCRIPTION PLANS ARE OFFERED AT A DISCOUNTED PRICE
            COMPARED TO THE SINGLE PURCHASE PRICE. CLIENTS WHO WISH TO CANCEL
            THEIR SUBSCRIPTION BEFORE SECOND SUBSCRIPTION CHARGE WILL BE
            REQUIRED TO PAY THE DIFFERENCE OF THE SUBSCRIPTION PLAN PRICE AND
            THE REGULAR PRICE TO THE COMPANY, IN CASE CLIENT SELECTS THE
            TERMINATION, THE CLIENT IS REQUIRED TO PAY THE PRICE DIFFERENCE
            BEFORE SUBSCRIPTION TERMINATION CAN BE COMPLETED. This measure is
            taken by the Company to avoid circumvention attempts by the Client.
          </StyledText>
          <StyledText>
            6.5.3 If Client fails to cancel subscription before the Cancellation
            Deadline but no longer wants the physical Goods, they must refer to
            section 7 of the Terms and Conditions.
          </StyledText>
          <StyledText>
            6.6 If Client has purchased the subscription of Supplements,
            shipping address change for recurring send outs can be done by
            contacting the support team by hello@nova.health at least 48 hours
            before the end of the current period. If the changes of the shipping
            address have not been made or have been made in violation of the
            procedure set out and the recurring send-out has been shipped, the
            Company is not responsible for any damages the Client has incurred
            or may incur accordingly.
          </StyledText>
          <StyledText>
            6.7. From time to time the Company might offer the Special Deals
            which may contain additional terms and conditions applicable
            together with this Agreement.
          </StyledText>
          <StyledText>
            6.7.1 The Company may offer the trials of paid subscriptions for the
            limited time at a special price or without payment (“Trial”). The
            Company will automatically begin charging the Client for the
            subscription on the first day following the end of the Trial on
            recurring basis of the interval what Company discloses in the
            Special Deal, chosen by the Client. If Client doesn’t want to be
            charged, he must cancel the subscription before the end of the
            Trial.
          </StyledText>
          <StyledHeading>7. Refund Policy and Limited Warranty</StyledHeading>

          <StyledText>
            7.1. The shipping duration of Goods typically ranges from 6 to 17
            business days, but during periods of high demand or unforeseen
            circumstances, delivery times may be extended.
          </StyledText>
          <StyledText>
            7.2. Under the applicable legal acts, the Client has a right to
            return delivered (received) Goods within 14 (fourteen) days from the
            date of delivery for refund as described in this Refund Policy. A
            refund can be issued if the product is a) proven to be not as
            described or b) faulty. The product must be unopened and in its
            original packaging in a condition fit for resale. Client must
            contact our customer support at hello@nova.health within 14 days of
            delivery and provide detailed information proving Company’s product
            fault (with visual proof attached). Any additional reasons for
            returns fall under chapter 7.5 in the Terms and Conditions.
          </StyledText>
          <StyledText>
            7.3 In the event where a client&apos;s order is missing or damaged
            in transit, the client has 14 calendar days after the purchase date
            to submit their claim. Any claims submitted after this period shall
            be deemed waived and of no effect. The following are accepted claim
            reasons:
          </StyledText>
          <StyledText>
            7.3.1 Delivered but Lost: In rare instances, a parcel may fail to
            reach you despite the carrier&apos;s tracking indicating delivery.
            If the tracking information confirms Delivered, but you cannot
            locate your package within 48 hours of the delivery timestamp,
            please perform the following steps:
          </StyledText>
          <StyledText>
            - Conduct a thorough search around the delivery location, including
            potential alternate delivery spots (e.g., nearby apartment
            entrances).
          </StyledText>
          <StyledText>
            - Check your mailbox or any designated mail storage areas.
          </StyledText>
          <StyledText>
            - Inquire with other household members to ascertain if anyone
            accepted the package on their behalf.
          </StyledText>
          <StyledText>
            - Send us your verified delivery address to verify that, that&apos;s
            what has been used during checkout.
          </StyledText>
          <StyledText>
            If, after 48 hours from the delivery timestamp, you remain unable to
            locate your package, please proceed to file a claim to
            help@nova.health confirming that you have followed the earlier
            steps.
          </StyledText>
          <StyledText>
            UPON APPROVAL, ALL APPLICABLE ORDERS RECEIVE REPLACEMENTS. WE DO NOT
            ISSUE REFUNDS. PLEASE NOTE: NOVA HEALTH CANNOT BE HELD RESPONSIBLE
            FOR ITEMS THAT ARE LOST OR STOLEN AFTER SUCCESSFUL DELIVERY.
          </StyledText>
          <StyledText>
            7.3.2 Damaged Order: Please note that our product packaging is
            designed to withstand the rigors of shipping and handling without
            requiring special treatment. However, if an order arrives damaged we
            will only provide a replacement at our sole discretion based upon
            photographic evidence of said damage.
          </StyledText>
          <StyledText>
            ALL APPLICABLE ORDERS RECEIVE REPLACEMENTS, AND NO REFUNDS ARE
            ISSUED.
          </StyledText>
          <StyledText>
            7.4 If a replacement is sent twice and the tracking information
            confirms delivery, yet the recipient does not receive the package on
            both occasions, the company shall hold the recipient responsible and
            will not issue any further replacements or refunds.
          </StyledText>
          <StyledText>
            7.5 PERISHABLE GOODS CANNOT BE RETURNED. AS NOVA HEALTH SUPPLEMENTS
            ARE PERISHABLE GOODS, WE CANNOT OFFER REFUNDS OR EXCHANGES ON ANY
            PURCHASES. We maintain strict quality standards for our products. In
            order to comply with our stringent quality protocols, we do not
            accept the return of any products once they have left our warehouse.
            As it is beyond our control to monitor the condition of goods after
            they have been dispatched, this policy is instituted to ensure you
            receive products that meet the highest quality. Our return policy is
            available on our website and is presented clearly during the
            checkout process. By making a purchase, you agree to this policy.
            This policy includes but is not limited to:
          </StyledText>
          <StyledText>
            7.5.1 Allergic reaction: If you are allergic to the product or any
            ingredient therein: Please note that our product contains a detailed
            label listing of all ingredients inside the checkout page, before
            you make your purchase. You are advised to read this label carefully
            and consult with your physician prior to purchase.
          </StyledText>
          <StyledText>
            REFUNDS CANNOT BE PROVIDED ON THESE GROUNDS. FOR FURTHER
            CLARIFICATION, YOU MAY CONTACT OUR CUSTOMER SERVICE TEAM AT
            HELP@NOVA.HEALTH FOR ASSISTANCE.
          </StyledText>
          <StyledText>
            7.5.2 Excess Quantity Ordered claims: Should you mistakenly order
            more products than intended, you must contact our customer support
            team at help@nova.health within 48 hours of your purchase.
          </StyledText>
          <StyledText>
            REGRETTABLY, OUR PRODUCTS ARE NOT ELIGIBLE FOR RETURN ONCE RECEIVED,
            HOWEVER WE MAY DELAY YOUR NEXT SUBSCRIPTION BY UP TO TWO MONTHS.
          </StyledText>
          <StyledText>
            7.5.3 The Company will not be responsible for the refund or
            reshipping the order to the other address if Client didn’t provide
            correct or full delivery or contact information (including delivery
            address, email address).
          </StyledText>
          <StyledText>
            7.5.4 Any returns made without prior arrangement and agreement by
            the Company shall incur a $15.00 investigation and product
            assessment fee to the Client.
          </StyledText>
          <StyledText>
            7.6 IN CASE THE AGREEMENT BETWEEN THE COMPANY AND THE CLIENT
            CONSISTS OF DIGITAL CONTENT WHICH IS NOT SUPPLIED ON A TANGIBLE
            MEDIUM, THE CLIENT AGREES TO LOSE HIS/HER RIGHT OF WITHDRAWAL OF THE
            AGREEMENT.
          </StyledText>
          <StyledText>
            7.6.1 The Company follows a no refund policy for purchased Digital
            content, unless the Digital content is proven to be not as described
            or faulty. In such cases, Client must contact our customer support
            at hello@nova.health within 14 days upon delivery and provide
            detailed information proving Company’s Digital Content fault (with
            visual proof attached). Once a refund is issued, Client no longer
            has access to the Company’s content. All refunds are applied to the
            original method of payment. By purchasing Digital Content, Client
            agrees to this refund policy and relinquishes any rights to subject
            it to any questions, judgment or legal actions.
          </StyledText>
          <StyledText>
            7.6.2 Client confirms that upon the Digital content is provided to
            him (immediately after the purchase), the Company’s obligations
            (namely, to provide the Digital Content) under this Agreement are
            fulfilled, namely, to provide the Digital Content.
          </StyledText>
          <StyledText>
            7.6.3 By purchasing Digital Content, Client provides consent to
            begin the performance of this contract and the Company has fulfilled
            its obligation to provide Client with such Digital content
          </StyledText>
          <StyledText>
            7.6.4 Considering the above mentioned, Client has explicitly waived
            their right of withdrawal during the 14 days period as all the
            preconditions stipulated in the applicable legislation were met.
          </StyledText>
          <StyledText>
            7.6.5 Any requests for proof of delivery for digital goods must be
            made within 180 days of order placement. Requests made after this
            period may not be fulfilled as there is no guarantee that the
            records will be available.
          </StyledText>
          <StyledText>
            7.7 Limited Warranty: The Company offers a 14 day limited warranty
            to replace defective and faulty physical Goods. The Company’s 14 day
            warranty and return policies cover ONLY products purchased from
            verified sellers who respect our quality control standards. Some
            Company products are sold by unverified companies or individuals.
            The Company cannot ensure the authenticity, effectiveness, quality,
            safety, or proper storage of products sold by any unverified seller.
          </StyledText>
          <StyledText>
            7.7.1 In the event of such defect or failure of any such product,
            the remedy described in 7.7 will be Client’s exclusive remedy.
          </StyledText>
          <StyledText>
            7.7.2 The Company will honor the policy only for products purchased
            from a reseller who we can confirm as purchasing directly from us
            and provides genuine, new and authentic products. All Goods must be
            inspected by Company to confirm the product falls under the warranty
            or return policy. We will repair or replace (at our option) the
            defective implement and refund Goods that fit the return policy.
            Customer is responsible for shipping charges to send the product in
            for warranty repair or replacement.
          </StyledText>
          <StyledText>
            7.7.3 Limitations/Exclusions: This limited warranty is
            non-transferable and does not apply to any purchaser who bought the
            product from a reseller or distributor not authorized by Company.
            This Warranty is in lieu of all other express warranties,
            obligations, or liabilities. This document constitutes the only
            warranty made by Company. No increase or alteration, written or
            verbal, of the obligation of this warranty is authorized.
          </StyledText>
          <StyledText>
            7.7.4 This limited warranty is void if the product has been altered,
            abused, misused, lost, damaged by accident, damaged by unauthorized
            adjustment and/or damaged by failure to provide reasonable and
            necessary care. this warranty does not apply to damage from shipping
            or normal wear and tear. Some states do not allow limitations on how
            long an implied warranty lasts, so the above limitation may not
            apply. Some states do not allow the exclusion or limitation of
            incidental or consequential damages, so the above exclusion may not
            apply. This limited warranty gives you specific legal rights, and
            you may also have other rights which vary from state to state.
          </StyledText>
          <StyledText>
            7.7.5 Amazon.com: Amazon customers are subject to Amazon terms and
            conditions. Company does not take responsibility for Amazon
            resellers.
          </StyledText>
          <StyledHeading>8. Intellectual Property Rights</StyledHeading>

          <StyledText>
            8.1. As between Company and Client, all intellectual property
            rights, including but not limited to copyright, design rights,
            trademark rights, patent rights and any other proprietary rights in
            or to related to the Services and Services-related content are owned
            by the Company.
          </StyledText>
          <StyledText>
            8.2. The Client must not reproduce, disassemble, reverse engineer,
            decompile, distribute, publicly display or perform, or publish or
            otherwise make available the Services including but not limited to
            Digital content, in whole or in part without Company’s prior written
            consent.
          </StyledText>
          <StyledText>
            8.3. The Client hereby grants to the Company a perpetual,
            irrevocable, worldwide, fully paid-up and royalty‑free,
            non-exclusive license, including the right to sublicense (through
            multiple tiers) and assign to third parties, to reproduce,
            distribute, perform and display (publicly or otherwise), create
            derivative works of, adapt, modify and otherwise use, analyze and
            exploit in any way now known or in the future discovered, his/her
            User Content (except for User Trademarks) as well as all modified
            and derivative works thereof. To the extent permitted by applicable
            laws, the Client hereby waives any moral rights he/she may have in
            any User Content. “User Content” means any User Trademarks,
            communications, images, writings, creative works, sounds, and all
            the material, data, and information, that the Client uploads,
            transmits or submits through the Services, or that other users
            upload or transmit. By uploading, transmitting or submitting any
            User Content, the Client affirms, represents and warrants that such
            User Content and its uploading, transmission or submission is (a)
            accurate and not confidential; (b) not in violation of any
            applicable laws, contractual restrictions or other third‑party
            rights, and that the Client has permission from any third party
            whose personal information or intellectual property is comprised or
            embodied in the User Content; and (c) free of viruses, adware,
            spyware, worms or other malicious code.
          </StyledText>
          <StyledText>
            8.4. No part of this Agreement is, or should be interpreted as a
            transfer of intellectual property rights in relation to the Services
            or Services-related content, except as expressly set forth in
            Section 8.1 below.
          </StyledText>
          <StyledHeading>9. Use of Digital content</StyledHeading>

          <StyledText>
            9.1. All intellectual property rights specified in Article 7.1 and
            relating to Digital content are owned by the Company. Digital
            content is licensed pursuant to this Section 8, and is not sold. The
            Client will only be granted a limited, revocable, non-exclusive,
            non-transferable and non-sublicensable license, subject to the terms
            and conditions of this Agreement, to use (solely for the Client’s
            individual use) any Digital content provided by Company to the
            Client.
          </StyledText>
          <StyledText>
            9.2. The term of this licence shall be for a term of 5 years from
            the date of the Client receiving the applicable Digital content,
            unless earlier suspended or terminated in accordance with this
            Agreement.
          </StyledText>
          <StyledText>
            9.3. Unless expressly otherwise provided, the Client must not use
            any Digital content except for personal, non-commercial purposes.
          </StyledText>
          <StyledText>
            9.4. The Client must not edit, reproduce, transmit or lend the
            Digital content or make it available to any third parties or use it
            to perform any other acts which extend beyond the scope of the
            licence provided in this Section 8 by the Company.
          </StyledText>
          <StyledText>
            9.5. The Company may impose restrictions on the scope of the licence
            or the number of devices or types of devices on which Digital
            content can be used.
          </StyledText>
          <StyledText>
            9.6. If the Client violates this Section 8, the Company may suspend
            access to the relevant Digital content, without limiting any of
            Company’s rights or remedies under this Agreement or applicable law,
            including Company’s right to recover from the Client the loss
            suffered as a result of or in connection with the infringement
            including any expenses incurred.
          </StyledText>
          <StyledHeading>10. Sale of Digital Content Prohibited</StyledHeading>

          <StyledText>
            10.1. The Client is prohibited from selling, offering for sale,
            sharing, renting out or lending Digital content, or copies of
            Digital content.
          </StyledText>
          <StyledHeading>11. Privacy Policy</StyledHeading>

          <StyledText>
            11.1. The processing of Client’s personal data is governed by the
            Privacy Policy. It is recommended for the Client to print and keep a
            copy of the Privacy Policy together with this Agreement.
          </StyledText>
          <StyledHeading>12. Indemnity</StyledHeading>
          <StyledText>
            12.1. The Client will indemnify and hold the Company, its
            affiliates, officers, directors, employees, agents, legal
            representatives, licensors, subsidiaries, joint ventures and
            suppliers, harmless from any claim or demand, including reasonable
            attorneys` fees, made by any third party due to or arising out of
            Client’s breach of this Agreement or use of the Services, or
            Client’s violation of any law or the rights of a third party in
            conjunction with Client’s breach of this Agreement or use of the
            Services.
          </StyledText>
          <StyledHeading>13. Liability</StyledHeading>
          <StyledText>
            13.1. INFORMATION MAY NOT BE APPROPRIATE OR SATISFACTORY FOR THE
            CLIENT USE, AND HE/SHE SHOULD VERIFY ALL INFORMATION BEFORE RELYING
            ON IT. ANY DECISIONS MADE BASED ON INFORMATION CONTAINED IN THE
            WEBSITE, INCLUDING INFORMATION RECEIVED THROUGH CLIENT`S USE OF THE
            SERVICES, ARE HIS/HER SOLE RESPONSIBILITY.
          </StyledText>
          <StyledText>
            13.2. THE CLIENT EXPRESSLY UNDERSTANDS AND AGREES THAT THE COMPANY
            SHALL NOT BE LIABLE FOR ANY DAMAGES WHATSOEVER (INCLUDING, WITHOUT
            LIMITATION, DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL,
            EXEMPLARY DAMAGES, OR THOSE RESULTING FROM LOST PROFITS, LOST DATA
            OR BUSINESS INTERRUPTION, LOSS OF GOODWILL, LOSS OF USE, OR OTHER
            LOSSES WHETHER BASED ON WARRANTY, CONTRACT, TORT OR ANY OTHER LEGAL
            THEORY (EVEN IF THE COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF
            SUCH DAMAGES), ARISING OUT OF: (i) THE USE OR INABILITY TO USE
            SERVICES, (ii) ANY LINK PROVIDED IN CONNECTION WITH THE SERVICES,
            (iii) THE MATERIALS OR INFORMATION CONTAINED AT ANY OR ALL SUCH
            LINKED WEBSITES OR MOBILE APP, (iv) CLIENT`S RELIANCE ON ANY OF THE
            SERVICES; (v) THE INTERRUPTION, SUSPENSION, TERMINATION OF THE
            SERVICES OR ANY PORTION THEREOF, (vi) THE TIMELINESS, DELETION,
            MISDELIVERY OR FAILURE TO POST OR STORE ANY INFORMATION, OR (vii)
            ANY MATTER OTHERWISE RELATED TO THE CLIENT`S USE OF THE SERVICES. IN
            NO EVENT SHALL THE COMPANY’S AGGREGATE LIABILITY TO THE CLIENT
            RELATING TO HIS/HER USE OF THE SERVICES EXCEED ONE HUNDRED DOLLARS
            ($100).
          </StyledText>
          <StyledText>
            13.3. A party to the Agreement shall be released from responsibility
            for non-fulfilment if it proves that this Agreement was not
            fulfilled due to force majeure. In particular, the Company shall not
            be liable for any losses caused by force majeure, riot, war or
            natural events or due to other occurrences for which the Company is
            not responsible (e.g. strike, lock-out, traffic hold-ups,
            administrative acts of domestic or foreign high authorities). The
            Client must provide written notification of the occurrence of force
            majeure, which prevents the fulfilment of this Agreement, within 30
            calendar days from the date of the occurrence of these
            circumstances. The Company shall inform the Client about the
            occurrence of force majeure by e-mail or on the Website or Mobile
            app if possible.
          </StyledText>
          <StyledText>
            13.4. Liable company: UAB &quot;Medical Score&quot; is an
            administrator of the Website and provider of the Services and the
            owner and the seller of the Goods, as well as the company
            responsible for the managing subscription services and payments for
            Services and Goods, refunds and chargebacks (depending on the
            payment method chosen), for Associated Companies. THE LIABILITY OF
            THE COMPANY IS LIMITED TO DIRECT LOSSES, UNLESS OTHERWISE PROVIDED
            UNDER THE APPLICABLE LAWS.
          </StyledText>
          <StyledText>
            13.5. Due to the nature of Services and/or Goods that the Company
            provides and as the Company cannot control the Client’s adherence to
            the provided use instructions, the Company provides no warranty as
            to any results or outcomes coming from using Services and/or Goods.
          </StyledText>
          <StyledText>
            13.6. When using Services the Client may receive links to other
            websites or mobile apps that are not owned and/or controlled by the
            Company. These are provided “as is”. The Client acknowledges and
            agrees that the Company is not responsible for the operation of such
            links. Furthermore, the Company is not responsible or liable for any
            content, advertising, products or other materials that may be
            accessed through such links and therefore the Client agrees that the
            Company shall not be responsible or liable, directly or indirectly
            for any damage or loss caused or alleged to be caused by or in
            connection with use or reliance on any such content, goods, services
            available on or through any such websites or mobile apps.
          </StyledText>
          <StyledHeading>14. Medical disclaimer</StyledHeading>

          <StyledText>
            14.1. BEFORE TRYING GOODS SOLD BY THE COMPANY, THE CLIENT SHOULD
            CONSULT WITH HIS/HER HEALTHCARE SERVICE PROVIDER.
          </StyledText>
          <StyledText>
            14.2. THE COMPANY IS NOT A MEDICAL ORGANIZATION AND IS NOT PROVIDING
            ANY MEDICAL ADVICE OR ASSISTANCE. NOTHING WITHIN THE SERVICES
            PROVIDED BY THE COMPANY IS ASSOCIATED WITH, SHOULD BE TAKEN AS, OR
            UNDERSTOOD AS MEDICAL ADVICE OR ASSISTANCE, NOR SHOULD IT BE
            INTERPRETED IN SUBSTITUTION FOR ANY MEDICAL ADVICE OR ASSISTANCE, OR
            USED OR REFERRED TO INSTEAD OF SEEKING APPROPRIATE MEDICAL ADVICE OR
            ASSISTANCE FROM HEALTH CARE PROVIDERS. THE CLIENT IS SOLELY
            RESPONSIBLE FOR EVALUATING AND ASSESSING HIS OWN HEALTH, INCLUDING
            ANY NEED TO SEEK APPROPRIATE GUIDANCE FROM A HEALTH CARE PROVIDER.
          </StyledText>
          <StyledHeading>15. Validity and Termination</StyledHeading>

          <StyledText>
            15.1. This Agreement is effective after the Client accepts and
            electronically expresses his/her consent to comply with them, and
            they shall remain in effect until terminated in accordance with the
            following section.
          </StyledText>
          <StyledText>
            15.2. The Company may terminate the relationship with the Client at
            any time in the following cases: (1) the Client does not agree with
            the Agreement; (2) the Client commits any breach of the Agreement;
            (3) the Client does not provide information requested by the Company
            and/or provides incorrect and/or incomprehensive information.
            Notwithstanding the foregoing, statutory termination rights shall
            not be affected.
          </StyledText>
          <StyledHeading>16. Changes to Agreement</StyledHeading>

          <StyledText>
            16.1. This Agreement, Privacy Policy and any additional terms and
            conditions that may apply are subject to change. The Company
            reserves the right to modify and update the Agreement from time to
            time and such changes shall be effective immediately upon posting to
            the Company’s Website.
          </StyledText>
          <StyledText>
            16.2. All amended Agreement, Privacy Policy and any additional terms
            and conditions will be posted online. The Company may give notice to
            the Client of any upcoming changes by sending an email to the
            primary email address provided by the Client, or notifying through
            the Website.
          </StyledText>
          <StyledText>
            16.3. The Client understands and agrees that any continued use and
            access to the Services after any posted updates of the Agreement,
            means that Client voluntary agrees to be bound by this Agreement. If
            Client does not agree to be bound by the updated Agreement, he/she
            should not use (or continue to use) the Services.
          </StyledText>
          <StyledHeading>17. Changes to Agreement</StyledHeading>
          <StyledText>
            17.1. In general, the Company prefers communication by e-mail. By
            accepting this Agreement, the Client accepts communication by
            e-mail. For this purpose, the Client is requested to have a valid
            e-mail address and provide it when filling required information as
            stipulated in Section 3.2. The Company may publish information
            related to this Agreement or Services on the Website. The Client
            should check his/her e-mail messages as well as information provided
            on the Website regularly and frequently. E-mails may contain links
            to further information and documents.
          </StyledText>
          <StyledText>
            17.2. Where applicable laws require provision of information on a
            durable medium, the Company will either send the Client an email
            with an attachment or send the Client a notification referring to
            the Services with download function to retain such information and
            documents permanently for future reference. It is the Client’s
            responsibility requested to keep copies of all communications from
            the Company.
          </StyledText>
          <StyledText>
            17.3. The Client may request a copy of this Agreement or any other
            contractual document by contacting hello@nova.health.
          </StyledText>
          <StyledText>
            17.4. The communication with the Client will be made in English,
            unless the Company and the Client agree to communicate in another
            language.
          </StyledText>
          <StyledText>
            17.5. The Client may contact us at any time by sending a message to
            hello@nova.health.
          </StyledText>
          <StyledHeading>18. Dispute resolution</StyledHeading>

          <StyledText>
            18.1. Governing Law. This Agreement is governed by the laws of Texas
            without regard to its principles of conflicts of law, and regardless
            of Client’s location.
          </StyledText>
          <StyledText>
            18.2. Informal Dispute Resolution. Client agrees to participate in
            informal dispute resolution before filing a claim against the
            Company.
          </StyledText>
          <StyledText>
            18.2.1. Any complaints in relation to the Goods and Services
            provided to the Client be addressed to UAB Medical Score, by
            contacting hello@nova.health.
          </StyledText>
          <StyledText>
            18.2.2. Any complaints in relation with subscriptions, refunds, and
            chargebacks for the Goods and Services, should be addressed to UAB
            Medical Score by contacting hello@nova.health.
          </StyledText>
          <StyledText>
            18.2.3. Any complaints in relation with subscriptions, refunds and
            chargebacks for the Goods and Services, should be addressed to UAB
            Medical Score by contacting hello@nova.health.
          </StyledText>
          <StyledText>
            18.2.4. Client should clearly indicate that a complaint is being
            submitted and specify the grounds and circumstances concerning the
            complaint. The Company will send a complaint acknowledgement to the
            e-mail address from which the complaint has been received. We will
            consider the complaint and respond to the Client within 14 calendar
            days of the day of receipt of a relevant complaint. If a dispute is
            not resolved within 30 calendar days of the day of receipt of a
            relevant complaint, Client or Company may bring a formal claim.
          </StyledText>
          <StyledText>
            18.3. Arbitration. Except for disputes that qualify for small claims
            court, all disputes arising out of or related to this Agreement or
            any aspect of the relationship between Client and Company, whether
            based in contract, tort, statute, fraud, misrepresentation or any
            other legal theory, will be resolved through final and binding
            arbitration before a white arbitrator instead of in a court by a
            judge or jury. Client and Company agrees that Client and Company are
            each waiving the right to trial by a jury. Such disputes include,
            without limitation, disputes arising out of or relating to
            interpretation or application of this arbitration provision,
            including the enforceability, revocability or validity of the
            arbitration provision or any portion of the arbitration provision.
            All such matters shall be decided by an arbitrator and not by a
            court or judge.
          </StyledText>
          <StyledText>
            18.4. Client agrees that any arbitration under this Agreement will
            take place on an individual basis; class arbitrations and class
            actions are not permitted and Client is agreeing to give up the
            ability to participate in a class action.
          </StyledText>
          <StyledText>
            18.5. Client may opt out of this agreement to arbitrate by emailing
            hello@nova.health with Client’s first name, last name, and address
            within thirty (30) days of accepting this agreement to arbitrate,
            with a statement that Client declines this arbitration agreement.
          </StyledText>
          <StyledText>
            18.6. The arbitration will be administered by the American
            Arbitration Association under its Consumer Arbitration Rules, as
            amended by this Agreement. The Consumer Arbitration Rules are
            available online at https://www.adr.org/consumer. The arbitrator
            will conduct hearings, if any, by teleconference or videoconference,
            rather than by personal appearances, unless the arbitrator
            determines upon request by Client or Company that an in-person
            hearing is appropriate. Any in-person appearances will be held at a
            location that is reasonably convenient to both parties with due
            consideration of their ability to travel and other pertinent
            circumstances. If the parties are unable to agree on a location,
            such determination should be made by the AAA or by the arbitrator.
            The arbitrator’s decision will follow the terms of this Agreement
            and will be final and binding. The arbitrator will have authority to
            award temporary, interim or permanent injunctive relief or relief
            providing for specific performance of this Agreement, but only to
            the extent necessary to provide relief warranted by the individual
            claim before the arbitrator. The award rendered by the arbitrator
            may be confirmed and enforced in any court having jurisdiction
            thereof. Notwithstanding any of the foregoing, nothing in this
            Agreement will preclude Client from bringing issues to the attention
            of federal, state or local agencies and, if the law allows, they can
            seek relief against us for you.
          </StyledText>
          <StyledHeading>19. Miscellaneous</StyledHeading>
          <StyledText>
            19.1. No person other than the Client shall have any rights under
            this Agreement.
          </StyledText>
          <StyledText>
            19.2. Client may not assign any rights under this Agreement to any
            third party without the prior consent of the Company. The Company at
            its sole discretion may assign its rights and obligations under this
            Agreement in full or in part to any third party.
          </StyledText>
          <StyledText>
            19.3. If any part of this Agreement is found by a court of competent
            jurisdiction to be invalid, unlawful or unenforceable then such part
            shall be severed from the remainder of the Agreement, which shall
            continue to be valid and enforceable to the fullest extent permitted
            by law.
          </StyledText>
          <StyledText>
            19.4. THE USE OF THE SERVICES IS SOLELY AT CLIENT`S OWN RISK. THE
            SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. THE
            COMPANY EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND WITH RESPECT
            TO THE SERVICES, WHETHER EXPRESS OR IMPLIED INCLUDING WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT
            OF INTELLECTUAL PROPERTY OR ARISING FROM A COURSE OF DEALING, USAGE
            OR TRADE PRACTICE. SOME STATES DO NOT ALLOW THE EXCLUSION OF IMPLIED
            WARRANTIES, SO THE ABOVE EXCLUSIONS MAY NOT APPLY TO THE CLIENT. THE
            COMPANY MAKES NO WARRANTY THAT THE SITE OR SERVICE WILL MEET
            CLIENT`S REQUIREMENTS, OR WILL BE UNINTERRUPTED, TIMELY, SECURE,
            CURRENT, ACCURATE, COMPLETE OR ERROR-FREE OR THAT THE RESULTS THAT
            MAY BE OBTAINED BY USE OF THE SITE OR SERVICE WILL BE ACCURATE OR
            RELIABLE. CLIENT UNDERSTAND AND ACKNOWLEDGE THAT HIS/HER SOLE AND
            EXCLUSIVE REMEDY WITH RESPECT TO ANY DEFECT IN OR DISSATISFACTION
            WITH THE SITE OR SERVICE IS TO CEASE TO USE THE SERVICES. CLIENT MAY
            HAVE OTHER RIGHTS, WHICH MAY VARY FROM STATE TO STATE.
          </StyledText>
          <StyledText>
            19.5. BY USING OR ACCESING THE SERVICES, CLIENT HEREBY ACKNOWLEDGES
            THAT HE/SHE HAS READ THIS AGREEMENT, UNDERSTOOD IT, AND AGREES TO BE
            BOUND BY ITS TERMS AND CONDITIONS.
          </StyledText>

          <StyledText>Last Updated: January 1st, 2024</StyledText>
        </Wrapper>
      </Container>
      <LCFooter disclaimerText={['© 2024 Nova Health. All rights reserved']} />
    </>
  );
};

export default Terms;

const MainContainer = styled.div`
  width: 100vw;
  background-size: cover;
  padding-top: 69px;
`;

const StyledList = styled.ol`
  counter-reset: section;
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 1rem;
`;

const StyledListElement = styled.li`
  &::before {
    content: '(' counter(section, lower-alpha) ') ';
    counter-increment: section;
  }
`;

const Wrapper = styled.div`
  margin: auto;
  width: 100%;
  max-width: 84rem;
`;

const StyledTitle = styled(Text)`
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 47px;
  line-height: 1.4;
  @media ${tablet} {
    font-size: 2rem;
  }
`;

const Container = styled.div`
  padding: 48px 3rem 40px;
  @media ${tablet} {
    padding: 48px 1rem 40px;
  }
`;

const StyledText = styled(Text)`
  margin-bottom: 1.375rem;
  color: #28293d;
  font-weight: 300;
  letter-spacing: -0.024em;
`;

const StyledHeading = styled(Text)`
  font-weight: 600;
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
`;
